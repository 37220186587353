.hidden {
    display: none !important;
}

.motor-control-card .ant-card-body {
    background-color: lightgray;
    padding: 10px;
}

.motor-control-card .ant-card-head {
    padding: 5px;
}

.motor-control-card .ant-card-extra {
    position: relative;
    right: -3px;
}

.small-icon {
    font-size: 14px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    padding: 2px;
    margin: 2px;
}

.small-image {
    width: 13px;
    height: 13px;
    margin: -2px 0 0 5px;
}

.small-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.small-form > div {
    margin-bottom: 10px;
}

.hub-details {
    display: flex;
    justify-content: space-between;
}

.tilt-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
}

.tilt-icon {
    font-size: 50pt;
    border-radius: 100px;
    background-color: lightgray;
    padding: 10px;
}

.tilt-value {
    width: 2em;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    padding: 8px 8px;
}

.motor-control-card {
    display: inline-block;
    margin: 0 10px 0 0;
    background-color: lightgray;
}
.motor-control-card > .ant-card-head {
    background-color: #f6f6f6;
}

.motor-control-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 0;
}

.motor-control-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
    padding: 0;
}

.motor-control-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.motor-control-card-body > div {
    margin-bottom: 10px;
}

.motor-control-card-body .ant-slider-with-marks {
    margin-bottom: 5px;
}

.track-control-container {
    display: flex;
    flex-direction: row;
}

.hub-controls {
    display: flex;
}

.shortcut-button {
    font-size: 9pt;
    width: 40px;
}

.shortcut-button-pressed {
    border: 1px solid #40a9ff;
}

.remote-control {
    display: flex;
}

.remote-control-hub {
    margin: 5px;
}

.remote-control .ant-slider-vertical .ant-slider-handle {
    margin-bottom: 0;
}

